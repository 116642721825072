import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
    IHeadRowVouchersReport,
    headVouchersReportColumnsDefault,
    headVouchersReportSelectableColumns,
} from 'data';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import ColumnChooser from 'components/ColumnChooser/ColumnChooser';
import NoResultsFound from 'components/NoResultsFound';
import SearchbyNumber from 'components/SearchByNumber';
import VouchersReportFilters from 'pages/Reports/Vouchers/VouchersReportFilters/VouchersReportFilters';
import VouchersReportTable from 'pages/Reports/Vouchers/VouchersReportTable/VouchersReportTable';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';
import VouchersAggregations from './VouchersAggregations/VouchersAggregations';

import { GlobalContext } from 'context/globalContext';

import Api from 'api/Api';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';
import formatStr from 'utils/formatStr';
import {
    startOfDay,
    endOfDay,
} from 'utils/formatDate';

import { setLanguageCookie } from 'utils/cookieMethods';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';
import {
    IAlertMessage,
} from 'api/models/reports';

import {
    Order,
    rowsPerPageOptions,
    VOUCHER_NUMBER_LENGTH,
    ORDER_BY_VOUCHERS_COLUMN,
    ORDER_BY_VOUCHERS_COLUMN_NAMES,
    EXCEL_EXPORT_ERROR_ENUM,
    REPORTS_NAMES,
    initialAlertMessage,
    localStorageFiltersKeys,
} from 'const';

import {
    IVouchersReportBaseFilterList,
    voucherIdSearchValidator,
    vouchersReportFilterListInitial as filterListInitial,
    IVouchersReportInitState,
    IVouchersReportFilterList,
    IVouchersAggregationsModel,
} from 'pages/Reports/business';

import {
    generateActiveColumsForExcel,
    generateVouchersSearchPayload,
    setAllFiltersListsData
} from 'pages/Reports/networkHelpers';

import { toggleItemInArray, sortBy } from 'utils/arrayMethods';
import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';
import { GlobalContextModel } from 'api/models/general';

import useStyles from './styles';

import { useWidth } from 'utils/customHooks';
import { getFromLocalStorage } from 'utils/devUtils';

const apiRequests = {
    getVouchersList: null,
    getTicketsCount: null,
    getAggregations: null
};

const aggregationResultInitial = {
    issuedVouchersQuantity: 0,
    issuedTotalValue: 0,
    cashoutVouchersQuantity: 0,
    cashoutTotalValue: 0,
    unpaidVouchersQuantity: 0,
    unpaidTotalValue: 0,
};

let pendingGetExport = false;
let lastSearchResultDateReportingApi;

const VouchersReport = (props) => {
    const classes = useStyles({});
    const { globalSettings, translations, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = useWidth() === 'xs';
    //range for filters
    const vouchersReportMaxRangeInDays = globalSettings?.settings?.VouchersReportSettings?.MaxRangeDays;
    const vouchersReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * vouchersReportMaxRangeInDays;

    const voucherExportReportMaxRangeINDays = globalSettings?.settings?.ExcelExportSettings?.VoucherReportMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.VoucherReportMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const vouchersExcelReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * voucherExportReportMaxRangeINDays;

    const filtersListsInitial: IVouchersReportBaseFilterList = {
        issuedByEntityIds: [],
        issuedBetshopIds: [],
        cashedOutByEntityIds: [],
        cashedOutBetshopIds: []
    };

    const { reportVouchersExportPermission } = permissions;

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.vouchersReportFilters);

    const [filtersLists, setFiltersLists] = useState(filtersListsInitial);

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));
    const fromDate = startOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );
    const toDate = endOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );

    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };

    const filterListStorageInitial = {
        ...localStorageFilters ? localStorageFilters : filterListInitial, fromDate, toDate
    };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState({ ...filterListStorageInitial });

    const [isAscendingOrder, setIsAscendingOrder] = useState(false);

    const [orderByColumn, setOrderByColumn] = useState<ORDER_BY_VOUCHERS_COLUMN>(ORDER_BY_VOUCHERS_COLUMN_NAMES.issueDate);

    const [areVouchersLoaded, setVouchersLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areVouchersLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rows, setRows] = useState([]);
    const [activeColumns, setActiveColumns]: [
        Array<IHeadRowVouchersReport>,
        React.Dispatch<React.SetStateAction<Array<IHeadRowVouchersReport>>>
    ] = useState(JSON.parse(localStorage.getItem('activeVouchersReportColumns')) || [...headVouchersReportColumnsDefault]);

    const [page, setPage] = useState<number>(0);

    const previousRowsPerPageRef= useRef(rowsPerPageOptions[0]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(previousRowsPerPageRef.current);
    const scrollDownRef = useRef(false);
    const [rowsCount, setRowsCount] = useState<number>(-1); // -1 means that rowsCount is not loaded yet

    const [searchText, setSearchText] = useState<string>('');
    const [searchResetKey, setSearchResetKey] = useState(Math.random()); // used to reset searchByNumber field state
    const [invalidSearchValue, setInvalidSearchValue] = useState(false);
    const [isSearchedByNumber, setIsSearchedByNumber] = useState(false);

    const [voucherIdFromSearch, setVoucherIdFromSearch] = useState<string>(null);

    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({ ...initialAlertMessage });

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const [aggregationsData, setAggregationsData] = useState<IVouchersAggregationsModel>(aggregationResultInitial);

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.vouchers];

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const fullSearchHandler = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPage = newRowsPerPage === rowsPerPage ? page : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
        showAggregations = true
    }: {
        newFilters?: IVouchersReportFilterList;
        newRowsPerPage?: number;
        newPage?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_VOUCHERS_COLUMN;
        showAggregations?: boolean;
    } = {}) => {
        setSearchText('');
        setSearchResetKey(Math.random());
        setInvalidSearchValue(false);
        setVouchersLoaded(false);

        const vouchersListPayload = generateVouchersSearchPayload(
            newFilters,
            newPage,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            null,
            null,
            timeZone,
            showAggregations
        );

        apiRequests.getVouchersList = Api.VouchersReport.GetVouchersList(vouchersListPayload);

        try {
            const {
                searchResult,
                aggregationResult,
                count,
                pageNumber,
                lastSearchResultDate
            } = await apiRequests.getVouchersList;

            setPage(pageNumber - 1);
            setRowsCount(count);
            setRows(searchResult);
            setIsSearchedByNumber(false);
            setVouchersLoaded(true);

            // BE returns aggregations = null if payload was sent with showAggregations = false
            aggregationResult !== null && setAggregationsData(aggregationResult);

            lastSearchResultDateReportingApi = lastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        }
    };

    const searchByIdHandler = async (voucherId: string) => {
        setVouchersLoaded(false);

        cancelRequests(apiRequests);
        apiRequests.getVouchersList = Api.VouchersReport.GetVouchersListById({ voucherId });

        try {
            const vouchersList = await apiRequests.getVouchersList;

            setPage(0);
            setRowsCount(vouchersList.length);
            setRows(vouchersList);
            setVouchersLoaded(true);
            setVoucherIdFromSearch(voucherId);
        } catch (error) {
            setVouchersLoaded(true);
            throwNetworkError(error);
        }
    };

    useLayoutEffect(() => {
        if (scrollDownRef.current) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;

            scrollDownRef.current = false;
        }
    }, [rows]);

    useEffect(() => {
        setAllFiltersListsData(apiRequests, setFiltersLists, globalSettings?.user?.ClientId)
            .then(() => {
                setFiltersListsLoaded(true);
                fullSearchHandler();
            }).catch(throwNetworkError);

        return () => cancelRequests(apiRequests);
    }, []);

    useEffect(() => {
        scrollDownRef.current = previousRowsPerPageRef.current !== rowsPerPage;
        previousRowsPerPageRef.current = rowsPerPage;
    }, [rowsPerPage]);

    const handleSort = (clickedOrderByColumn: ORDER_BY_VOUCHERS_COLUMN) => {
        let newAscendingOrder = isAscendingOrder;
        let newOrderByColumn = clickedOrderByColumn;

        if (clickedOrderByColumn === orderByColumn) {
            newAscendingOrder = !isAscendingOrder;
            setIsAscendingOrder(newAscendingOrder);
        } else {
            setOrderByColumn(newOrderByColumn);
        }

        cancelRequests(apiRequests);
        fullSearchHandler({ newIsAscendingOrder: newAscendingOrder, newOrderByColumn, showAggregations: false });
    };

    const handleColumnChoose = (event, id: string) => {
        const item = headVouchersReportSelectableColumns.find(item => item.id === id);
        const selectedIndex = activeColumns.findIndex(item => item.id === id);

        const activeColumnsUpdated = sortBy('position', toggleItemInArray(item, selectedIndex, activeColumns));

        localStorage.setItem('activeVouchersReportColumns', JSON.stringify(activeColumnsUpdated));
        setActiveColumns(activeColumnsUpdated);
    };

    const searchTextSubmitHandler = (value: string) => {
        if (value.length) {
            localStorage.removeItem(localStorageFiltersKeys.vouchersReportFilters);
            setAppliedFilters({ ...filterListInitialWithDate });
            setIsSaveFilters(false);

            const isValid = value.length === VOUCHER_NUMBER_LENGTH && !value.split('').every(symbol => symbol === '0');

            if (isValid) {
                setInvalidSearchValue(false);
                setSearchText(value);
                setIsSearchedByNumber(true);
                searchByIdHandler(value);
            } else {
                setInvalidSearchValue(true);
                setRows([]);
            }
        } else {
            setInvalidSearchValue(false);
            setIsSearchedByNumber(false);
        }
    };

    const handleChangePage = (event, newPage: number) => {
        cancelRequests(apiRequests);
        fullSearchHandler({ newPage, showAggregations: false });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);

        cancelRequests(apiRequests);
        fullSearchHandler({ newRowsPerPage: event.target.value, showAggregations: false });
    };

    const handleFiltersApply = (filters) => {
        cancelRequests(apiRequests);

        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListInitial) {
                if (filterListInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = filters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.vouchersReportFilters, JSON.stringify(filtersToStorage));
        }

        if (filters.toDate.getTime() - filters.fromDate.getTime() <= vouchersReportMaxRangeInMilliseconds) {
            setAlertMessage({ ...alertMessage, show: false });
            fullSearchHandler({ newFilters: { ...filters } });
        } else {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-vauchers-reports-max-days-range'],
                    { 'days': vouchersReportMaxRangeInDays }
                )}`
            });
        }

        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.vouchers]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });

        setAppliedFilters(filters);

    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.vouchersReportFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const resetToDefault = () => {
        localStorage.removeItem(localStorageFiltersKeys.vouchersReportFilters);
        setIsSaveFilters(false);
        handleFiltersApply({ ...filterListInitialWithDate });
        setVoucherIdFromSearch(null);
    };

    const handleClearFilters = () => {
        localStorage.removeItem(localStorageFiltersKeys.vouchersReportFilters);
        setAppliedFilters({ ...filterListInitialWithDate });
        setIsSaveFilters(false);
        fullSearchHandler({ newFilters: { ...filterListInitialWithDate } });
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.vouchers]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAlertMessage({ ...alertMessage, show: false });
    };

    const handleRedirect = (voucherId: number) => {
        const state: IVouchersReportInitState = {
            filtersLists,
            isSearchedByNumber,
            appliedFilters,
            isAscendingOrder,
            rowsPerPage,
            rows,
            rowsCount,
            voucherId,
            page,
            searchText,
            orderByColumn
        };

        props.history.replace({
            pathname: '/reports/vouchers',
            state
        });
        props.history.push({
            pathname: `/reports/vouchers/${voucherId}`,
            state
        });
    };

    const handleLoadingExcel = async () => {
        if (rows.length == 0){
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > vouchersExcelReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': voucherExportReportMaxRangeINDays }
                )}`
            });
        } else {
            let activeColumnsExport = generateActiveColumsForExcel(activeColumns);

            activeColumnsExport.includes('voucherId') && activeColumnsExport.push('isCMV');

            const payload = generateVouchersSearchPayload(
                {
                    ...appliedFilters
                },
                0,
                0,
                isAscendingOrder,
                orderByColumn,
                lastSearchResultDateReportingApi,
                activeColumnsExport,
                timeZone,
                true,
                voucherIdFromSearch ?? voucherIdFromSearch,
            );

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.VouchersReport.GetExport(
                    setLanguageCookie({ ...payload, reportType: EXPORT_REPORT_TYPE.Vouchers }, globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);

                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic,
                                    REPORTS_NAMES.vouchers)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.vouchers]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const VouchersReportFiltersWithParams = (
        <VouchersReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
            handleClearFilters={handleClearFilters}
            handleSaveFilters={handleSaveFilters}
            isSaveFilters={isSaveFilters}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.searchWrap} data-a="vouchers-number-search">
                    <SearchbyNumber
                        initValue={searchText}
                        searchResetKey={searchResetKey}
                        placeholder={translations['emp-reports-vouchers-search']}
                        onSubmit={searchTextSubmitHandler}
                        onReset={resetToDefault}
                        searchValidator={voucherIdSearchValidator}
                        maxSymbols={7}
                    />
                </div>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title}
                        component="h1"
                        noWrap
                    >
                        {translations['gen-vouchers']}
                    </Typography>
                    {isMobileView &&
                        <div className={classes.actions}>
                            {VouchersReportFiltersWithParams}
                            {reportVouchersExportPermission &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />)}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                {!isSearchedByNumber && (
                                    <DateRangeLegend
                                        translationKey="emp-reports-results-range-title"
                                        fromDate={appliedFilters.fromDate}
                                        toDate={appliedFilters.toDate}
                                    />)}
                                <div className={classes.options}>
                                    {VouchersReportFiltersWithParams}
                                    <ColumnChooser
                                        activeColumns={activeColumns}
                                        allColumns={headVouchersReportSelectableColumns}
                                        onHandleClick={handleColumnChoose}
                                    />
                                    {reportVouchersExportPermission &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {
                                    translations['gen-dismiss']
                                }
                            </AlertMessageFullWidth>
                        </div>
                        {
                            !isSearchedByNumber && !invalidSearchValue &&
                                <VouchersAggregations
                                    aggregationsData={aggregationsData}
                                />
                        }
                        <Box className={classes.resultsWrapper}>
                            {(rows?.length && !invalidSearchValue)
                                ? (
                                    <VouchersReportTable
                                        page={page}
                                        rows={rows}
                                        rowsCount={rowsCount}
                                        hideSorting={isSearchedByNumber}
                                        hidePagination={isSearchedByNumber}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        activeColumns={activeColumns}
                                        handleSort={handleSort}
                                        order={isAscendingOrder ? Order.ascending : Order.descending}
                                        orderByColumn={orderByColumn}
                                        isMobileView={isMobileView}
                                        handleRedirect={handleRedirect}
                                    />)
                                : (
                                    <Box paddingTop={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <NoResultsFound
                                                    onResetClick={resetToDefault}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(VouchersReport);
