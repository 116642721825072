import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';

import { useHistory, useLocation } from 'react-router';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { supervisorApprovalFilterList } from 'data';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import NoResultsFound from 'components/NoResultsFound';
import SearchbyNumber from 'components/SearchByNumber';
import SupervisorReportFilters from 'pages/Reports/Supervisor/SupervisorReportFilters/SupervisorReportFilters';
import SupervisorReportTable from 'pages/Reports/Supervisor/SupervisorReportTable/SupervisorReportTable';
import DateRangeLegend from 'components/DateRangeLegend';
import ButtonWithLinearProgress from 'components/Buttons/ButtonWithLinearProgress';
import AlertMessageFullWidth from 'components/Notifications/AlertMessageFullWidth';

import { GlobalContext } from 'context/globalContext';

import { INetworkBetshop } from 'api/models/network-management';

import {
    startOfDay,
    endOfDay,
} from 'utils/formatDate';

import {
    IReportSupervisorModel,
    ISupervisorApprovalsSearchResponse,
    ISupervisorsListModel,
    IAlertMessage
} from 'api/models/reports';

import Api from 'api/Api';
import { cancelRequests, throwNetworkError } from 'utils/requestCancelation';

import { EXPORT_REPORT_TYPE } from 'api/models/reportsExport';

import {
    Order,
    rowsPerPageOptions,
    EXCEL_EXPORT_ERROR_ENUM,
    REPORTS_NAMES,
    ORDER_BY_SUPERVISOR_APPROVALS_COLUMN,
    ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_NAMES,
    TRANSACTION_TYPE,
    initialAlertMessage,
    localStorageFiltersKeys,
} from 'const';

import formatStr from 'utils/formatStr';

import { setLanguageCookie } from 'utils/cookieMethods';

import {
    ISupervisorReportBaseFilterList,
    ISupervisorReportFilterList,
    documentIdSearchValidator,
    supervisorReportFilterListInitial as filterListInitial,
    ISupervisorReportInitState,
} from 'pages/Reports/business';

import {
    generateApprovalsSearchPayload,
    getAllBetshops,
    getSupervisorsList,
} from 'pages/Reports/networkHelpers';

import { mqttClient, initMqttConnection, subscribe as subscribeToMqtt } from 'api/mqttClient';
import { onMessageExcelHandler, ExportToExcelContext, state as mqttState, setState as setMqttState } from 'context/exportToExcel';

import useStyles from 'pages/Reports/Tickets/styles';
import { CancelablePromise } from 'api/services/_BaseApi';

import { GlobalContextModel } from 'api/models/general';

import { useWidth } from 'utils/customHooks';
import { getFromLocalStorage } from 'utils/devUtils';

type IApiRequests = {
    getAllBetshops: any | CancelablePromise<Array<INetworkBetshop>>;
    getApprovalsPage: null | CancelablePromise<ISupervisorApprovalsSearchResponse>;
    getApprovalsPageById: null | CancelablePromise<Array<IReportSupervisorModel>>;
    getSupervisorsList: null | CancelablePromise<ISupervisorsListModel>;
}

const apiRequests: IApiRequests = {
    getAllBetshops: null,
    getApprovalsPage: null,
    getApprovalsPageById: null,
    getSupervisorsList: null,
};

let pendingGetExport = false;
let lastSearchResultDateReportingApi;

const SupervisorReport = () => {
    const { pathname } = useLocation<ISupervisorReportInitState>();
    const history = useHistory();
    const classes = useStyles({});
    const { globalSettings, translations, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);

    const isMobileView = useWidth() === 'xs';

    const supervisorReportMaxRangeInDays = globalSettings?.settings?.ExcelExportSettings?.SupervisorReportMaxRangeDays != null
        ? globalSettings?.settings?.ExcelExportSettings?.SupervisorReportMaxRangeDays
        : globalSettings?.settings?.ExcelExportSettings?.DefaultReportMaxRangeDays;
    const supervisorReportMaxRangeInMilliseconds = 1000 * 60 * 60 * 24 * supervisorReportMaxRangeInDays;

    const filtersListsInitial: ISupervisorReportBaseFilterList = {
        betShops: [],
        supervisorApprovals: supervisorApprovalFilterList.map(({ id, name }) => ({ id, name: translations[name] })),
        supervisors: [],
    };

    const { reportSupervisorExportPermission } = permissions;

    const localStorageFilters = getFromLocalStorage(localStorageFiltersKeys.supervisorReportFilters);

    const [filtersLists, setFiltersLists] = useState(filtersListsInitial);

    const dateInTimeZone = new Date(new Date().toLocaleString('en', { timeZone }));
    const fromDate = startOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );
    const toDate = endOfDay(
        dateInTimeZone,
        {
            gameDayEnable: globalSettings?.settings?.GamingDaySettings?.Enabled,
            startOfGamingDay: globalSettings?.settings?.GamingDaySettings?.Start,
            endOfGamingDay: globalSettings?.settings?.GamingDaySettings?.End
        }
    );

    const filterListInitialWithDate = { ...filterListInitial, fromDate, toDate };

    const filterListStorageInitial = {
        ...localStorageFilters ? localStorageFilters : filterListInitial, fromDate, toDate
    };

    // applied filters (as reference values when closing filters drawer for resetting if it is not applied)
    const [appliedFilters, setAppliedFilters] = useState({ ...filterListStorageInitial });

    const [isAscendingOrder, setIsAscendingOrder] = useState(false);

    const [areRowsLoaded, setRowsLoaded] = useState(false);
    const [areFiltersListsLoaded, setFiltersListsLoaded] = useState(false);
    const isLoaded = areRowsLoaded && areFiltersListsLoaded;

    const isReadyToShowRef = useRef(false);

    isLoaded && (isReadyToShowRef.current = true);

    const [rows, setRows] = useState<Array<IReportSupervisorModel>>([]);

    const [page, setPage] = useState(0);
    const previousRowsPerPageRef= useRef(rowsPerPageOptions[0]);
    const [rowsPerPage, setRowsPerPage] = useState(previousRowsPerPageRef.current);
    const scrollDownRef = useRef(false);

    const [rowsCount, setRowsCount] = useState(-1);

    const [searchText, setSearchText] = useState<string>('');
    const [searchResetKey, setSearchResetKey] = useState(Math.random());
    const [invalidSearchValue, setInvalidSearchValue] = useState(false);
    const [isSearchedByDocumentID, setIsSearchedByDocumentID] = useState(false);
    const [documentIdFromSearch, setDocumentIdFromSearch] = useState<string>(null);

    const [orderByColumn, setOrderByColumn] = useState<ORDER_BY_SUPERVISOR_APPROVALS_COLUMN>(ORDER_BY_SUPERVISOR_APPROVALS_COLUMN_NAMES.approvalDate);
    const [alertMessage, setAlertMessage] = useState<IAlertMessage>({ ...initialAlertMessage });

    const [isSaveFilters, setIsSaveFilters] = useState(!!localStorageFilters);

    const ExportToExcelState = useContext(ExportToExcelContext);
    const {
        percentage: valueExportToExcel,
        isLoading: isLoadingExportToExcel,
        isFailed: isFailedExportToExcel,
        errorCode: excelExportError
    } = ExportToExcelState[REPORTS_NAMES.supervisor];

    isFailedExportToExcel && setAlertMessage({
        show: true,
        message: excelExportError === EXCEL_EXPORT_ERROR_ENUM.NoData
            ? translations['emp-transaction-nodata-error']
            : translations['general-error']
    });

    const fullSearchHandler = async ({
        newFilters = appliedFilters,
        newRowsPerPage = rowsPerPage,
        newPage = newRowsPerPage === rowsPerPage ? page : 0,
        newIsAscendingOrder = isAscendingOrder,
        newOrderByColumn = orderByColumn,
    }: {
        newFilters?: ISupervisorReportFilterList;
        newRowsPerPage?: number;
        newPage?: number;
        newIsAscendingOrder?: boolean;
        newOrderByColumn?: ORDER_BY_SUPERVISOR_APPROVALS_COLUMN;
    } = {}) => {
        setSearchText('');
        setSearchResetKey(Math.random());
        setInvalidSearchValue(false);
        setRowsLoaded(false);

        const payload = generateApprovalsSearchPayload(
            newFilters,
            newPage,
            newRowsPerPage,
            newIsAscendingOrder,
            newOrderByColumn,
            timeZone
        );

        apiRequests.getApprovalsPage = Api.SupervisorReport.GetApprovalsPage(payload);

        try {
            const {
                searchResult: approvalsList,
                count,
                pageNumber,
                lastSearchResultDate
            } = await apiRequests.getApprovalsPage;

            setPage(pageNumber - 1);
            setRowsCount(count);
            setRows(approvalsList);
            setIsSearchedByDocumentID(false);
            setRowsLoaded(true);
            lastSearchResultDateReportingApi = lastSearchResultDate;
        } catch (error) {
            throwNetworkError(error);
        }
    };

    const searchByIdHandler = async (documentId: string) => {
        setRowsLoaded(false);
        cancelRequests(apiRequests);

        apiRequests.getApprovalsPageById = Api.SupervisorReport.GetApprovalsPageByDocumentId({ documentId, betShopIds: [] });

        try {
            const approvalsList = await apiRequests.getApprovalsPageById;

            setPage(0);
            setRowsCount(approvalsList.length);
            setRows(approvalsList);
            setIsSearchedByDocumentID(true);
            setRowsLoaded(true);
            setDocumentIdFromSearch(documentId);
        } catch (error) {
            throwNetworkError(error);
        }
    };

    useLayoutEffect(() => {
        if (scrollDownRef.current) {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;

            scrollDownRef.current = false;
        }
    }, [rows]);

    useEffect(() => {
        getAllBetshops(
            apiRequests,
            setFiltersLists,
            globalSettings?.user?.ClientId
        )
            .then(() => getSupervisorsList(apiRequests, setFiltersLists))
            .then(() => {
                setFiltersListsLoaded(true);
                fullSearchHandler();
            })
            .catch(throwNetworkError);

        return () => cancelRequests(apiRequests);
    }, []);

    useEffect(() => {
        scrollDownRef.current = previousRowsPerPageRef.current !== rowsPerPage;
        previousRowsPerPageRef.current = rowsPerPage;
    }, [rowsPerPage]);

    const handleChangePage = (event, newPage: number) => {
        cancelRequests(apiRequests);
        fullSearchHandler({ newPage });
    };

    const handleSort = (clickedOrderByColumn: ORDER_BY_SUPERVISOR_APPROVALS_COLUMN) => {
        let newAscendingOrder = isAscendingOrder;
        let newOrderByColumn = clickedOrderByColumn;

        if (clickedOrderByColumn === orderByColumn) {
            newAscendingOrder = !isAscendingOrder;
            setIsAscendingOrder(newAscendingOrder);
        } else {
            setOrderByColumn(newOrderByColumn);
        }

        cancelRequests(apiRequests);
        fullSearchHandler({ newIsAscendingOrder: newAscendingOrder, newOrderByColumn });
    };

    const searchTextSubmitHandler = (value: string) => {
        if (value.length) {
            localStorage.removeItem(localStorageFiltersKeys.supervisorReportFilters);
            setAppliedFilters({ ...filterListInitialWithDate });
            setIsSaveFilters(false);

            const isValid = documentIdSearchValidator(value);

            setInvalidSearchValue(!isValid);

            if (isValid) {
                setSearchText(value);
                searchByIdHandler(value);
            } else {
                setRows([]);
            }
        } else {
            setInvalidSearchValue(false);
            setIsSearchedByDocumentID(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        cancelRequests(apiRequests);

        fullSearchHandler({ newRowsPerPage: event.target.value });
    };

    const handleFiltersApply = (filters) => {
        if (isSaveFilters) {
            const filtersToStorage = {};

            for (let key in filterListInitial) {
                if (filterListInitial.hasOwnProperty(key)) {
                    filtersToStorage[key] = filters[key];
                }
            }

            localStorage.setItem(localStorageFiltersKeys.supervisorReportFilters, JSON.stringify(filtersToStorage));
        }

        cancelRequests(apiRequests);

        setAlertMessage({ ...alertMessage, show: false });
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.supervisor]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });
        setAppliedFilters(filters);

        fullSearchHandler({ newFilters: { ...filters }, newPage: 0 });
    };

    const handleSaveFilters = () => {
        isSaveFilters && localStorage.removeItem(localStorageFiltersKeys.supervisorReportFilters);

        setIsSaveFilters(!isSaveFilters);
    };

    const resetToDefault = () => {
        localStorage.removeItem(localStorageFiltersKeys.supervisorReportFilters);
        setIsSaveFilters(false);
        handleFiltersApply({ ...filterListInitialWithDate });
        setDocumentIdFromSearch(null);
    };

    const handleClearFilters = () => {
        localStorage.removeItem(localStorageFiltersKeys.supervisorReportFilters);
        setAppliedFilters({ ...filterListInitialWithDate });
        setIsSaveFilters(false);
        fullSearchHandler({ newFilters: { ...filterListInitialWithDate } });
    };

    const handleLoadingExcel = async () => {
        if (rows.length == 0) {
            setAlertMessage({ show: true, message: translations['emp-transaction-nodata-error'] });
        } else if (appliedFilters.toDate.getTime() - appliedFilters.fromDate.getTime() > supervisorReportMaxRangeInMilliseconds) {
            setAlertMessage({
                show: true,
                message: `${formatStr(
                    translations['emp-transaction-export-to-excel-error-days'],
                    { 'days': supervisorReportMaxRangeInDays }
                )}`
            });
        } else {
            const payload = generateApprovalsSearchPayload(
                appliedFilters,
                rowsPerPage,
                page,
                isAscendingOrder,
                orderByColumn,
                timeZone,
                lastSearchResultDateReportingApi,
                documentIdFromSearch ?? documentIdFromSearch,
            );

            if (!pendingGetExport) {
                pendingGetExport = true;
                Api.SupervisorReport.GetExport(
                    setLanguageCookie({ ...payload, reportType: EXPORT_REPORT_TYPE.SupervisorApprovals }, globalSettings.languages, 'languageId'))
                    .then(res => {
                        const {
                            jwtToken,
                            reportId
                        } = res;

                        if (jwtToken) {
                            !mqttClient?.connected && initMqttConnection(jwtToken, globalSettings.settings.MqttSettigs.BrokerKongUrl);

                            subscribeToMqtt({
                                topic: globalSettings.settings.MqttSettigs.ReportsExportStatusTopic + reportId,
                                onMessageHandler: (response) => onMessageExcelHandler(
                                    response,
                                    reportId,
                                    globalSettings.settings.MqttSettigs.ReportsExportStatusTopic,
                                    REPORTS_NAMES.supervisor)
                            });
                        }
                    }).catch((error) => {
                        setMqttState({
                            ...mqttState,
                            [REPORTS_NAMES.supervisor]: {
                                percentage: 0,
                                isLoading: false,
                                isFailed: true,
                                errorCode: null
                            }
                        });
                        throwNetworkError(error);
                    }).finally(() => pendingGetExport = false);
            }
        }
    };

    const handleHideAlert = () => {
        setMqttState({
            ...mqttState,
            [REPORTS_NAMES.supervisor]: {
                percentage: 0,
                isLoading: false,
                isFailed: false,
                errorCode: null
            }
        });

        setAlertMessage({ ...alertMessage, show: false });
    };

    const handleRedirect = (
        id: string,
        transactionType: TRANSACTION_TYPE
    ) => {

        const state: ISupervisorReportInitState = {
            filtersLists,
            appliedFilters,
            isAscendingOrder,
            rowsPerPage,
            rowsCount,
            rows,
            page,
            isSearchedByDocumentID,
            searchText,
        };

        if (transactionType == TRANSACTION_TYPE.ticketPayWin || transactionType == TRANSACTION_TYPE.cashierBetPlacement) {
            history.replace({
                pathname: '/reports/supervisor',
                state
            });

            history.push({
                pathname: `/reports/tickets/${id}`,
                state: {
                    pathFrom: pathname
                }
            });
        } else if (transactionType == TRANSACTION_TYPE.voucherIssue || transactionType == TRANSACTION_TYPE.voucherRedeem) {
            history.replace({
                pathname: '/reports/supervisor',
                state
            });

            history.push({
                pathname: `/reports/vouchers/${id}`,
                state: {
                    pathFrom: pathname
                }
            });
        }
    };

    const SupervisorReportFiltersWithParams = (
        <SupervisorReportFilters
            filtersLists={filtersLists}
            appliedFilters={appliedFilters}
            setAppliedFilters={handleFiltersApply}
            filterListInitialWithDate={filterListInitialWithDate}
            handleClearFilters={handleClearFilters}
            handleSaveFilters={handleSaveFilters}
            isSaveFilters={isSaveFilters}
        />
    );

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.searchWrap}>
                    <SearchbyNumber
                        initValue={searchText}
                        searchResetKey={searchResetKey}
                        placeholder={translations['emp-reports-supervisor-search']}
                        onSubmit={searchTextSubmitHandler}
                        onReset={resetToDefault}
                        searchValidator={documentIdSearchValidator}
                    />
                </div>
                <div className={classes.titleWrap}>
                    <Typography className={classes.title}
                        component="h1"
                        noWrap
                    >
                        {translations['gen-supervisor-report-title']}
                    </Typography>
                    {isMobileView &&
                        <div className={classes.actions}>
                            {SupervisorReportFiltersWithParams}
                            {reportSupervisorExportPermission &&
                                <div style={{ marginLeft: '8px' }}>
                                    <ButtonWithLinearProgress
                                        label={translations['emp-transaction-export-to-excel'] as string}
                                        value={valueExportToExcel}
                                        handleLoading={handleLoadingExcel}
                                        loading={isLoadingExportToExcel && !isFailedExportToExcel}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
                {isMobileView && (
                    <DateRangeLegend
                        translationKey="emp-reports-results-range-title"
                        fromDate={appliedFilters.fromDate}
                        toDate={appliedFilters.toDate}
                    />)}
            </div>
            {<Divider className={classes.divider} />}
            <div className={classes.paper}>
                <BackdropSpinner open={!isLoaded} />
                {isReadyToShowRef.current && (
                    <>
                        {!isMobileView && (
                            <div className={classes.resultsHeader}>
                                {!isSearchedByDocumentID && (
                                    <DateRangeLegend
                                        translationKey="emp-reports-results-range-title"
                                        fromDate={appliedFilters.fromDate}
                                        toDate={appliedFilters.toDate}
                                    />)}
                                <div className={classes.options}>
                                    {SupervisorReportFiltersWithParams}
                                    {reportSupervisorExportPermission &&
                                        <div style={{ marginLeft: '8px' }}>
                                            <ButtonWithLinearProgress
                                                label={translations['emp-transaction-export-to-excel'] as string}
                                                value={valueExportToExcel}
                                                handleLoading={handleLoadingExcel}
                                                loading={isLoadingExportToExcel}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className={classes.alertWrapper}>
                            <AlertMessageFullWidth
                                label={alertMessage.message}
                                show={alertMessage.show}
                                handleHideAlert={handleHideAlert}
                            >
                                {
                                    translations['gen-dismiss']
                                }
                            </AlertMessageFullWidth>
                        </div>
                        <Box className={classes.resultsWrapper}>
                            {(rows.length && !invalidSearchValue)
                                ? (
                                    <SupervisorReportTable
                                        page={page}
                                        rows={rows}
                                        rowsCount={rowsCount}
                                        hideSorting={isSearchedByDocumentID}
                                        rowsPerPage={rowsPerPage}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        handleSort={handleSort}
                                        handleRedirect={handleRedirect}
                                        order={isAscendingOrder ? Order.ascending : Order.descending}
                                        orderByColumn={orderByColumn}
                                        isMobileView={isMobileView}
                                    />
                                )
                                : (
                                    <Box paddingTop={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <NoResultsFound
                                                    onResetClick={resetToDefault}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )
                            }
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default SupervisorReport;
